import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Paragraph } from '@twilio-paste/paragraph';
import Changelog from '@twilio-paste/text/CHANGELOG.md';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { InlineCode } from '../../../components/Typography';
export const pageQuery = graphql`
  {
    allPastePrimitive(filter: {name: {eq: "@twilio-paste/text"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/primitives/text/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Text" categoryRoute={SidebarCategoryRoutes.PRIMITIVES} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/primitives/text" storybookUrl="/?path=/story/primitives-text--default" data={props.data.allPastePrimitive.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <LivePreview scope={{
          Text
        }} language="jsx" mdxType="LivePreview">
  {`<Text as="p">Hello</Text>`}
        </LivePreview>
        <h2>{`Guidelines`}</h2>
        <Callout mdxType="Callout">
  <CalloutTitle as="h3" mdxType="CalloutTitle">Please note</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Only use this component if all other Paste typographic components don’t meet your use case. If you find yourself
    here, come to the Design System Office Hours.
  </CalloutText>
        </Callout>
        <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
        <h3>{`About Text`}</h3>
        <p>{`The Text component is a design primitive. It is designed to be used when other typographic components in Paste do not
meet your requirements. It is a styled component that allows you to create text styles with values from our design tokens.
When using the Text component you can create any type of UI text and always be using the Twilio design language.`}</p>
        <p>{`The Text component allows you to set style properties for common text styles. These properties include font family,
color and line-height. You can only select design tokens as the value of each property. You can use any combination of
the different properties, though.`}</p>
        <p>{`Style properties on the Text component can be set with a single value or a responsive value. By setting a responsive
value, you can control how your text will appear at each breakpoint the
`}<a parentName="p" {...{
            "href": "https://paste.twilio.design/tokens/theme-package/"
          }}>{`theme`}</a>{` supports. This can be useful in supporting responsive designs
for different screen sizes.`}</p>
        <p>{`Via the `}<inlineCode parentName="p">{`as`}</inlineCode>{` property, you can also set the Text component to render as any HTML element.`}</p>
        <h3>{`Accessibility`}</h3>
        <p>{`The Text component can be used in any situation. As always, when working with HTML you need to make a careful choice
to select an appropriate semantic for your text. That semantic communicates the intended meaning of that text to
assistive technology.`}</p>
        <Callout mdxType="Callout">
  <CalloutText mdxType="CalloutText">
    Be sure to use the <strong>"as"</strong> prop to select semantically meaningful HTML elements. The{' '}
    <strong>"as"</strong> property is required. As a fallback, the Text component will render as a{' '}
    <InlineCode mdxType="InlineCode">span</InlineCode> which may not be suitable for your use-case.
  </CalloutText>
        </Callout>
        <h2>{`Examples`}</h2>
        <h3>{`Basic text`}</h3>
        <LivePreview scope={{
          Text
        }} language="jsx" mdxType="LivePreview">
  {`<Text
  as="p"
  fontWeight="fontWeightMedium"
  fontSize="fontSize30"
  marginBottom="space40"
  color="colorTextSuccess"
>
  Hello
</Text>`}
        </LivePreview>
        <h3>{`Responsive text`}</h3>
        <p>{`You can set an array of values to any style property supported by the Text component. By providing an array of styles
you can declare how the text should appear for each breakpoint. You can provide as many styles as there are breakpoints
supported by the theme. Each style you provide in the array will match a breakpoint in the theme object, based on the
same order.`}</p>
        <LivePreview scope={{
          Text
        }} language="jsx" mdxType="LivePreview">
  {`<Text
  as="p"
  fontSize={["fontSize20", "fontSize40", "fontSize60"]}
  color={["colorTextBrandHighlight", "colorTextSuccess", "colorTextError"]}
>
  Hello
</Text>`}
        </LivePreview>
        <h3>{`Tokens as pseudo-class props`}</h3>
        <p>{`To view the full list of pseudo-class props, check the `}<a parentName="p" {...{
            "href": "/primitives/text#props"
          }}>{`props table`}</a>{`.`}</p>
        <LivePreview scope={{
          Text
        }} language="jsx" mdxType="LivePreview">
  {`<Text
  _hover={{color: 'colorTextLink', cursor: "pointer"}}
>
Hover over me!
</Text>`}
        </LivePreview>
        <h2>{`When to use Text`}</h2>
        <p>{`There are many pre-styled text components in Paste. For example, `}<a parentName="p" {...{
            "href": "/components/heading"
          }}>{`Heading`}</a>{`, `}<a parentName="p" {...{
            "href": "/components/paragraph"
          }}>{`Paragraph`}</a>{` and InputLabel. You should use
those components wherever possible. Only when your use case cannot be met by these components should you reach for the
Text component.`}</p>
        <p>{`The best use case for the Text component is when you are working with a design partner and are creating a net new
experience not already covered by Paste. The Text component will be the easiest way to recreate your new experience
using the Twilio design language.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Explore what’s in Paste that will meet your needs first. For example, the Paragraph and Heading components" preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don’t reach for the Text component as your first option" preview={false} mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use the Text component to create entirely new experiences not covered by Paste" preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don’t use the Text Component to recreate a slightly different version of a pre-existing Paste component" preview={false} mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use the Text component for all styled text that doesn’t already exist in Paste" preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don’t use base HTML elements for your text since there are no global styles" preview={false} mdxType="Dont" />
        </DoDont>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Always set the “as” property to ensure you are making a suitable semantic choice" preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don’t use the Text component without setting the “as” property" preview={false} mdxType="Dont" />
        </DoDont>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/text - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Text} from '@twilio-paste/text';

const Component = () => (
  <Text as="p" fontSize="fontSize70" fontWeight="fontWeightMedium">
    Foo
  </Text>
);
`}</code></pre>
        <h4>{`Props`}</h4>
        <p>{`All the regular HTML attributes (`}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{`, `}<inlineCode parentName="p">{`type`}</inlineCode>{`, and so on) including the following custom props:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`as?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`keyof JSX.IntrinsicElements`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A custom HTML tag`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`div`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`display?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.DisplayProperty>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`fontFamily?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape[fonts]>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`fontSize?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape[fontSizes]>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`fontStyle?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.FontStyleProperty>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`fontWeight?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape[fontWeights]>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`letterSpacing?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.LetterSpacingProperty<TLengthStyledSystem>>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`lineHeight?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape[lineHeights]>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`margin?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our spacing tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`marginTop?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our spacing tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`marginRight?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our spacing tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`marginBottom?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our spacing tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`marginLeft?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our spacing tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`overflow`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.OverflowProperty>;`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`overflowX`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.OverflowXProperty>;`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`overflowY`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.OverflowYProperty>;`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our spacing tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`paddingBottom?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our spacing tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`paddingLeft?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our spacing tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`paddingRight?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our spacing tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`paddingTop?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['space']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our spacing tokens`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`textAlign?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.textAlignProperty>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`color?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<keyof ThemeShape['textColors']>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`textDecoration?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.TextDecorationProperty<CSS.TextDecorationLineProperty>>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`textOverflow?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.TextOverflowProperty>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`whitespace?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.WhiteSpaceProperty>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`after?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`before?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`focus?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`hover?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`active?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`pressed?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`selected?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`focusWithin?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`invalid?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`disabled?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`grabbed?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`expanded?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`checked?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`mixed?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`odd?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`even?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`visited?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`readOnly?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`first?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`last?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`notFirst?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`_`}{`notLast?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextProps`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      